import { Fragment, useEffect, useState } from 'react';
import classes from './Pedidos.module.css';
import HeaderAuth from '../Layout/MainHeader';

import CartAddProduto from '../Cart/CartAddProduto';
import CartEditProduto from '../Cart/CartEditProduto';

import {
    fetchProdutos,
    deleteProduto
} from '../../server/sgbd';

const Produto = (props) => {

    const [produtos, setProdutos] = useState([]);

    const [cartAddProduto, setCartAddProduto] = useState(false);

    const [editProduto, setEditProduto] = useState(false);
    const [cartEditProduto, setCartEditProduto] = useState(false);

    const showCartHandler = () => {
        setCartAddProduto(true);
    };

    const hideCartHandler = () => {
        setCartAddProduto(false);
        carregarProdutos();
    };

    const editHandler = (prod) => {
        console.log("set");
        console.log(prod);
        setEditProduto(prod);
        setCartEditProduto(true);
    };

/*     const showCartHandlerEdit = () => {
        setCartEditProduto(true);
    }; */

    const hideCartHandlerEdit = () => {
        setCartEditProduto(false);
        carregarProdutos();
    };

    const handleDelete = async (id) => {
        /* const result = await  */deleteProduto(id);
        carregarProdutos();
      };

    useEffect(() => {
        carregarProdutos();
    }, []); // Executa apenas uma vez ao montar o componente

    const carregarProdutos = async () => {
        try {
            const data = await fetchProdutos(); // Chama a função fetchProdutos para obter os produtos
            setProdutos(data); // Atualiza o estado de produtos com os dados obtidos
        } catch (error) {
            console.error('Erro ao carregar produtos:', error);
        }
    };
    return (
        <Fragment>
             {cartAddProduto && <CartAddProduto onClose={hideCartHandler} />} 
              

            <HeaderAuth goToPedidos={() => props.goToPedidos()}
                goToProdutos={() => props.goToProdutos()}
                goToRelatorios={() => props.goToRelatorios()}
                onExit={() => props.onExit()} />

            <div className={classes.exibirprodutos} >
                <h1>Produtos Disponíveis</h1>

                
                    {/*  <button onClick={() => showCartHandlerEdit()} >EDITAR PRODUTO</button> */}


                    {cartEditProduto && editProduto && <CartEditProduto produtoED={editProduto} onClose={hideCartHandlerEdit} />}

                <ul>
                    {produtos.map((produto) => (
                        <li key={produto.produtoid} onClick={() => editHandler(produto)} > 
                           {/*  ID: {produto.produtoid} -  <span onClick={() => editHandler(produto)}>Edit</span> */}{/* - <span onClick={() => handleDelete(produto.produtoid)}>Delete</span> -*/}  <strong>{produto.nome}</strong> - R$ {produto.preco}
                        </li>
                    ))}
                </ul>
<div className={classes.divbutton} >
                <button /* className={classes.green} */ onClick={() => showCartHandler()} >NOVO PRODUTO</button>
                   </div>

            </div>



        </Fragment >
    );
};

export default Produto;