import { useEffect, useState/* , useContext */ } from 'react';

/* import CartContext from './store/cart-context'; */

import Pedidos from './components/Auth/Pedidos';
import Produtos from './components/Auth/Produtos';
import Relatorios from './components/Auth/Relatorios';

import LoadingSpinner from './components/load/loader';

import Header from './components/Layout/Header';
import Contato from './components/Layout/Contato';
import Sobre from './components/Layout/Sobre';
import Funcionarios from './components/Layout/Funcionarios';

/* import Politica from './components/Layout/Politica.js';
import Termos from './components/Layout/Termos.js'; */

/* import SecondHeader from './components/Layout/SecondHeader'; */

import Meals from './components/Meals/Meals';
import Cart from './components/Cart/Cart';
import CartDelivery from './components/Cart/CartDelivery';
import CartProvider from './store/CartProvider';

/* import Login from './components/Log/Login'; */

import { Routes, Route/* , BrowserRouter */ } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { useStore } from './store/store.js';

// Firebase
/* import { initializeApp } from "firebase/app"; */
/* import { getAnalytics } from "firebase/analytics"; */

//import { getFirestore, collection, /* setDoc, */ addDoc, /* getDoc, doc, updateDoc,  query, orderBy, startAt, endAt, getDocs,*/ serverTimestamp } from "firebase/firestore";
//import { storage } from "firebase/storage";
//import { getAuth, /* createUserWithEmailAndPassword, */ signInWithEmailAndPassword, onAuthStateChanged, /* sendPasswordResetEmail, */ signOut/* , updateProfile */ } from "firebase/auth";

/* import classes from './App.module.css'; */

import {
  /*   fetchProdutos,
    finalizarCompra,
    adicionarProduto,
    fetchCategorias,
    fetchClientes,
    fetchPedidos,
    addAdmin, */
  loginAdmin,
  registrarPedidoCPF,
  registrarPedidoCNPJ,
} from './server/sgbd';

/* const firebaseConfig = {
  apiKey: "AIzaSyDcrfFSHP0WJfpJnUeArdrRyVb9NH44V5k",
  authDomain: "delivery-6cfd4.firebaseapp.com",
  projectId: "delivery-6cfd4",
  storageBucket: "delivery-6cfd4.appspot.com",
  messagingSenderId: "688119345969",
  appId: "1:688119345969:web:4aea374d969ced86d6f610",
  measurementId: "G-Y6YHVGS13X"
}; */
/* const app = initializeApp(firebaseConfig); */
/* const analytics = getAnalytics(app); */
/* const db = getFirestore(app); */

function App() {
/*   const [cartIsShown, setCartIsShown] = useState(false);
  const [cartDeliveryIsShown, setCartDeliveryIsShown] = useState(false); */
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  const [destinatario, setDestinatario] = useState(false);
  const [conteudoCarro, setConteudoCarro] = useState(false);

/*   const [buscaItem, setBuscaItem] = useState(''); */

  const dispatch = useStore(true)[1];
  const Data = useStore(true)[0];

  /*   const cartCtx = useContext(CartContext); */

  useEffect(() => {
/*     console.log("Data.isAuthenticated");
    console.log(Data.isAuthenticated); */

    if (Data.isAuthenticated) {
      setUser(Data.email);
      navigate("/auth");
    }
    else {
      navigate("/");
    }

  }, [Data.isAuthenticated]);

  /*   const [loading, setLoading] = useState(true); */


  //const adicionar = addAdmin("JOAO", "admin@gmail.com", "admin123");

  
/*   const goToItem = (e) => {
    navigate("/");
    setBuscaItem(e);

  }; */

  const goToCardapio = () => {
    navigate("/");
  };
  const goToContato = () => {
    navigate("/contato");
  };
  const goToSobre = () => {
    navigate("/sobre");
  };
  const goToFuncionarios = () => {
    navigate("/auth");
  };

  const goToPedidos = () => {
    navigate("/auth/pedidos");
  };

  const goToProdutos = () => {
    navigate("/auth/produtos");
  };

  const goToRelatorios = () => {
    navigate("/auth/relatorios");
  };


  const showCartHandler = () => {
    navigate("/cart");
    /*     setCartIsShown(true); */
  };

  const hideCartHandler = () => {
    navigate("/");
    /*     setCartIsShown(false); */
  };

  const showCartDeliveryHandler = (conteudo) => {
    /*     console.log("conteudo");
        console.log(conteudo); */
    setConteudoCarro(conteudo);
    navigate("/deliver");

    /*     hideCartHandler();
        setCartDeliveryIsShown(true); */
  };

/*   const hideCartDeliveryHandler = () => {
    setCartDeliveryIsShown(false);
  }; */

  const backToCartHandler = () => {
/*     setCartDeliveryIsShown(false);
    setCartIsShown(true); */
    navigate("/cart");
  };


  const loginHandler = async (email, password) => {
    try {
      const response = await loginAdmin(email, password);
      console.log(response);
      /* dispatch('login'); */
      dispatch('updateAuth', { isAuthenticated: true, pageName: "/auth", user: response.admin.nome, email: response.admin.email });
      /* console.log(Data); */

      navigate("/auth/pedidos");
      /*       console.log('Login feito:', response); */

    } catch (error) {
      // Trata erros na requisição ou processamento
      console.error('Erro ao fazer login:', error);
      // Pode mostrar uma mensagem de erro para o usuário, por exemplo:
      // setError('Erro ao tentar fazer login. Por favor, tente novamente mais tarde.');
    }
  };

  const singOutHandler = () => {
    /*     const auth = getAuth(); */
    /*     signOut(auth).then(() => {
          // Sign-out successful.
          navigate("/");
        }).catch((error) => {
          // An error happened.
        }); */
    navigate("/");
    setUser(null);
  };



  const registrarCompra = async () => {
    /*     const docRefUp = collection(db, "pedidos"); */
    /*     var buyTime = serverTimestamp(); */

    const itens = conteudoCarro.items;
    const total = conteudoCarro.totalAmount;
    console.log(itens);  
    /* var status = "PROCESSANDO"; */
    /*     registrarPedido()
    
        const docUp = addDoc(docRefUp, { ...destinatario, itens, total, buyTime, status }).then(function (docR) {
          console.log("Document written with ID: ", docR.id);
        }); */
    /*     console.log("react itens");
        console.log(itens); */
    try {

      /* const pedidoData = {
      nome: destinatario.nome,
      cpf: destinatario.cpf,
      endereco: destinatario.endereco,
      telefone: destinatario.telefone,
      pagamento: destinatario.pagamento,
      itens: itens,
      total: total,
    } */
      /*         console.log("destinatario.cpf.length");
              console.log(destinatario.cpf.length); */
      if (destinatario.cpf.length === 11) {
        /*           console.log("destinatario CPF");
                  console.log(destinatario); */
        const pedidoData = {
          nome: destinatario.nome,
          cpf: destinatario.cpf,
          endereco: destinatario.endereco,
          telefone: destinatario.telefone,
          pagamento: destinatario.pagamento,
          itens: itens,
          total: total,
        }
        const response = await registrarPedidoCPF(pedidoData);
        /*           console.log('Pedido registrado com sucesso! ID:', response.pedidoid); */
      }

      if (destinatario.cpf.length === 14) {
        /*           console.log("destinatario CNPJ");
                  console.log(destinatario); */
        const pedidoData = {
          nome: destinatario.nome,
          cnpj: destinatario.cpf,
          endereco: destinatario.endereco,
          telefone: destinatario.telefone,
          pagamento: destinatario.pagamento,
          itens: itens,
          total: total,
        }
        const response = await registrarPedidoCNPJ(pedidoData);
        /*           console.log('Pedido registrado com sucesso! ID:', response.pedidoid); */
      }
      /* const response = await registrarPedido(pedidoData); */


      // Redireciona para outra página após o registro do pedido
      /* history.push('/confirmacao-pedido'); */
    } catch (error) {
      console.error('Erro ao registrar pedido:', error.message);
      // Tratar erro (ex.: mostrar mensagem de erro para o usuário)
    }

  };

  const atualizarDados = (dataform) => {
    setDestinatario(dataform);
  };

  return (
    <CartProvider>
      <LoadingSpinner /> 
      <Routes>


        <Route path={"/cart"} element={
          <div>
            <Header onShowCart={showCartHandler}
              goToCardapio={() => goToCardapio()}
              goToContato={() => goToContato()}
              goToSobre={() => goToSobre()}
              goToFuncionarios={() => goToFuncionarios()} />

            <Cart onClose={hideCartHandler} onNext={showCartDeliveryHandler} /* goToItem={(e) => goToItem(e)} *//>
          </div>
        }>
        </Route>

        <Route path={"/deliver"} element={
          <div>
            <Header onShowCart={showCartHandler}
              goToCardapio={() => goToCardapio()}
              goToContato={() => goToContato()}
              goToSobre={() => goToSobre()}
              goToFuncionarios={() => goToFuncionarios()} />

            <CartDelivery onBack={backToCartHandler} /* onClose={hideCartDeliveryHandler} */ onDeliveryChange={atualizarDados} onBuy={registrarCompra} />
          </div>
        }>
        </Route>

        <Route path={"/"} element={
          <div>

            {/* {cartIsShown && <Cart onClose={hideCartHandler} onNext={showCartDeliveryHandler} />}
            {cartDeliveryIsShown && <CartDelivery onBack={backToCartHandler} onClose={hideCartDeliveryHandler} onDeliveryChange={atualizarDados} onBuy={registrarCompra} />}
 */}
            <Header onShowCart={showCartHandler}
              goToCardapio={() => goToCardapio()}
              goToContato={() => goToContato()}
              goToSobre={() => goToSobre()}
              goToFuncionarios={() => goToFuncionarios()} />

            <main>
              <Meals /* setBusca = {buscaItem} *//* carrinho={CartContext} */ />
            </main>
          </div>
        }>
        </Route>

        <Route path={"/contato"} element={
          <div>
            <Header onShowCart={() => { goToCardapio(); showCartHandler(); }}
              goToCardapio={() => goToCardapio()}
              goToContato={() => goToContato()}
              goToSobre={() => goToSobre()}
              goToFuncionarios={() => goToFuncionarios()} />
            <Contato />
          </div>}>
        </Route>

        <Route path={"/sobre"} element={
          <div>
            <Header onShowCart={() => { goToCardapio(); showCartHandler(); }}
              goToCardapio={() => goToCardapio()}
              goToContato={() => goToContato()}
              goToSobre={() => goToSobre()}
              goToFuncionarios={() => goToFuncionarios()} />
            <Sobre />
          </div>}>
        </Route>

        <Route path={"/auth"} element={
          <div>
            <Header onShowCart={() => { goToCardapio(); showCartHandler(); }}
              goToCardapio={() => goToCardapio()}
              goToContato={() => goToContato()}
              goToSobre={() => goToSobre()}
              goToFuncionarios={() => goToFuncionarios()} />
            <Funcionarios onLogin={loginHandler} />
            {/* {!user && <div className={classes.AppForm}>
              <Login onLogin={loginHandler} /></div>} */}
          </div>}>
        </Route>

        <Route path={"/auth/pedidos"} element={
          <div>
            {user && <div >

              <Pedidos usuario={user} onExit={singOutHandler}
                goToPedidos={() => goToPedidos()}
                goToProdutos={() => goToProdutos()}
                goToRelatorios={() => goToRelatorios()}
              />
            </div>}
          </div>
        }>
        </Route>

        <Route path={"/auth/produtos"} element={
          <div>
            {user && <div >
              <Produtos usuario={user} onExit={singOutHandler}
                goToPedidos={() => goToPedidos()}
                goToProdutos={() => goToProdutos()}
                goToRelatorios={() => goToRelatorios()}
              />
            </div>}
          </div>
        }>
        </Route>

        <Route path={"/auth/relatorios"} element={
          <div>
            {user && <div >
              <Relatorios usuario={user} onExit={singOutHandler}
                goToPedidos={() => goToPedidos()}
                goToProdutos={() => goToProdutos()}
                goToRelatorios={() => goToRelatorios()}
              />
            </div>}
          </div>
        }>
        </Route>

      </Routes>
    </CartProvider >
  );
}

export default App;
