import Card from '../UI/Card';
import MealItem from './MealItem/MealItem';
import classes from './AvailableMeals.module.css';
import { useEffect, useState/* , useContext */ } from 'react';

import LoadingSpinner from '../load/loader';

import {
  fetchProdutos
} from '../../server/sgbd';

const AvailableMeals = (props) => {
  const [DUMMY_MEALS, setProdutos] = useState([]);
/*   console.log("props.setTermo");
  console.log(props.setTermo); */

  useEffect(() => {
/*     console.log("SOLICITAÇÃO PRODUTOS"); */
    const fetchData = async () => {

      // Exemplo de uso das funções de API
      const produtosData = await fetchProdutos(props.setTermo);
/*       console.log("SET PRODUTOS"); */
      setProdutos(produtosData);
    };
    fetchData();
  }, [props.setTermo]);

/*   console.log(DUMMY_MEALS); */

  const queijoList = DUMMY_MEALS.map((meal) => (
    (meal.Tipo === 'Queijo') && <MealItem  
      key={meal.Produtoid}
      id={meal.ProdutoID}
      name={meal.Nome}
      description={meal.Descricao}
      price={meal.Preco}
    />
  ));

  const laticiniosList = DUMMY_MEALS.map((meal) => (
    (meal.Tipo === 'Laticínio') && <MealItem  
    key={meal.Produtoid}
    id={meal.ProdutoID}
    name={meal.Nome}
    description={meal.Descricao}
    price={meal.Preco}
    />
  ));

  const molhosList = DUMMY_MEALS.map((meal) => (
    (meal.Tipo === 'Molho') && <MealItem 
    key={meal.Produtoid}
    id={meal.ProdutoID}
    name={meal.Nome}
    description={meal.Descricao}
    price={meal.Preco}
    />
  ));

  /*   const mealsList = DUMMY_MEALS.map((meal) => (
      <MealItem
        key={meal.produtoid}
        id={meal.produtoid}
        name={meal.nome}
        description={meal.descricao}
        price={meal.preco}
      />
    )); */

  return (
    <section className={classes.meals}>


      {queijoList.some(item => Boolean(item)) && <h2 className={classes.titulos} >QUEIJOS</h2>}
      {queijoList.some(item => Boolean(item)) && <Card>
        <ul>{queijoList}</ul>
      </Card>}

{/*       {console.log("laticiniosList")}
      {console.log(laticiniosList)} */}
      {laticiniosList.some(item => Boolean(item)) && <h2 className={classes.titulos}>LATICINIOS</h2>}
      {laticiniosList.some(item => Boolean(item)) && <Card>
        <ul>{laticiniosList}</ul>
      </Card>}

      {molhosList.some(item => Boolean(item)) && <h2 className={classes.titulos}>MOLHOS</h2>}
      {molhosList.some(item => Boolean(item)) && <Card>
        <ul>{molhosList}</ul>
      </Card>}

      {!queijoList.some(item => Boolean(item)) &&
        !laticiniosList.some(item => Boolean(item)) &&
        !molhosList.some(item => Boolean(item)) &&
        <div>
        <LoadingSpinner />
        <h2 className={classes.titulos} >Não foram encontrados itens procurados.</h2></div>}

    </section>
  );
};

export default AvailableMeals;
